<template>
  <div>
      <div class="flex-div" style="margin-top: 45px;">
          <span class="item-name">家长学号</span>
          <el-input v-model="sId" placeholder="请填写家长的学号"></el-input>
      </div>
      <el-row>
        <el-button type="primary" size="large" round  @click="search">检索</el-button>
        <el-button type="primary" size="large" plain round @click="$router.push('/record')">申诉记录</el-button>
        <input style="visibility: hidden;" @change="getImg" ref="img" accept="image/*" type="file" />
      </el-row>
      <div v-if="Object.keys(searchResult).length !== 0">
        <div class="result-div">
          <small-title detail-title="检索结果" style="margin-bottom: 20px;"></small-title>
          <div v-for="(item,i) in showTits" :key="i" class="result-item">
              <span>{{item}}</span>
              <span>{{searchResult[showKeys[i]]}}</span>
          </div>
          <textarea class="desc" placeholder="备注说明" v-model="desc"></textarea>
          <div class="result-item">与所冲突顾问沟通截图</div>
          <div class="flex-div">
              <div class="img-item" v-for="(img, index) in imgs" :key="index">
                  <img width="100" height="100" :src="img" alt="">
                  <div class="icon-img" @click="imgs.splice(index, 1)">
                    <img width="20" height="20" src="~@/assets/icon_shanchu.png" alt="">
                  </div>
              </div>
              <div class="img-item camera" @click="$refs.img.click()" v-if="imgs.length < 3">
                  <img width="32" height="30" src="~@/assets/camera.png" alt="">
              </div>
          </div>
        </div>
        <el-button type="primary" size="large" round @click="submit">提交</el-button>
      </div>
  </div>
</template>

<script>
    import {GetParentSearchRes, SubmitAppeal} from "@/api";
    import {smallTitle, titleUnderlineChoice} from "@/components";
    import {getLoading} from '@/utils'
    import axios from 'axios'

    export default {
        name: "conflict",
        components: {smallTitle, titleUnderlineChoice},
        data() {
            return {
                choices: ['权限申请', '冲突申诉'],
                choiceIndex: 1,

                sId: '',

                showTits: ['家长学号：', '家长昵称：', '家长电话：', '顾问姓名：', '顾问电话：'],
                showKeys:['student_id','nickname','phone','consultant','consultant_phone'],
                searchResult: {},
                desc: '',
                imgs: []
            }
        },
        watch: {
          sId(value) {
            if (value.trim() == '') {
              this.searchResult = {}
            }
          }
        },
        methods: {
            makeChoice(i) {
                if(this.choiceIndex !== i){
                    this.$router.push('/apply')
                }
            },
            search() {
                let that = this;
                GetParentSearchRes({'student_id':that.sId}).then((res) => {
                    that.searchResult = res.data
                }).catch((e)=>{
                    that.$message.info(e.msg)
                })
            },
            getImg(e) {
                var files = new FormData()
                files.append('file', e.currentTarget.files[0])
                axios.post('https://api.dongjinyu.com/common/file/upload', files, {}).then(res => {
                    this.imgs.push(res.data.data)
                }).catch(err => {
                    console.log(err)
                })
            },
            submit() {
                  console.log(this.desc);
                if (this.desc == '' || this.imgs.length == 0) {
                  this.$message.warning('请输入描述并上传沟通记录');
                } else {
                  this.$loading()
                  SubmitAppeal({
                    student_id: this.sId,
                    img: this.imgs,
                    desc: this.desc
                  }).then(res => {
                    if (res.code == 200) {
                      this.$message.success('提交成功')
                      getLoading(this).close()
                      setTimeout(() => {
                        this.sId = ''
                      }, 1000)
                    }
                  }).catch((e)=>{
                      that.$message.info(e.msg)
                      getLoading(this).close()
                  })
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../style/permissionCommon";
    .result-div {
        margin-top: 26px;
        padding-top: 15px;
        padding-left: 20px;
        padding-bottom: 30px;
        box-sizing: border-box;
        width: 800px;
        // height: 270px;
        background: #F2F5F8;

        .result-item {
            margin-top: 16px;
            color: #404040;
        }

        .img-item {
            position: relative;
            width: 100px;
            height: 100px;
            border-radius: 8px;
            background: #fff;
            margin-right: 20px;
            margin-top: 20px;

            .icon-img {
                position: absolute;
                height: 20px;
                top: -10px;
                right: -10px;
            }
        }

        .camera {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ccc;
        }
    }

    .btn-div {
        margin-top: 25px;
        margin-left: 80px;
    }

    .el-row /deep/.el-button,
    .el-row /deep/.el-button+.el-button {
      width: 100px;
      margin-left: 80px;
      margin-top: 40px;
    }

    .desc {
      width: 600px;
      height: 110px;
      background: #fff;
      padding: 15px;
      resize: none;
      outline: none;
      border: none;
      border-radius: 10px;
      margin-top: 20px;
    }

    /deep/.el-button {
      width: 225px;
      margin-top: 30px;
      margin-left: 100px;
    }
</style>